import React from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

export const MapContainer = ({ google, latitude, longitude, children }) => {
  return (
    <Map google={google} zoom={12} initialCenter={{ lat: latitude, lng: longitude }}>
      {children}
    </Map>
  );
};

export default GoogleApiWrapper(props => ({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  language: 'en'
}))(MapContainer);
