import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import AreaContext from '../../context/area/areaContext';
import Spinner from '../generic/spinner/Spinner';
import AreaItem from './AreaItem';
import MapContainer from '../map/MapContainer';
import MarkerContainer from '../map/MarkerContainer';

const AreasTabbed = () => {
  const LIST = 'LIST';
  const MAP = 'MAP';
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(LIST);
  const areaContext = useContext(AreaContext);
  const { loading, areas } = areaContext;
  const refs = [];

  if (loading) {
    return <Spinner />;
  }

  const handleAreaClick = index => {
    setSelectedTab(MAP);
    setSelectedIndex(index);
  };

  const handleMapClick = index => {
    setSelectedTab(LIST);
    setSelectedIndex(index);
  };

  const renderAreaItems = () => {
    return areas.map((area, index) => {
      refs.push(React.createRef());

      return (
        <AreaItem
          key={area.postal_code}
          ref={refs[index]}
          rank={index + 1}
          selected={selectedIndex === index}
          onClick={() => handleAreaClick(index)}
          area={area}
        />
      );
    });
  };

  const renderMarkers = () => {
    return areas.map((area, index) => {
      const props = {
        key: area.postal_code,
        area: area,
        onClick: () => handleMapClick(index)
      };

      if (selectedIndex === index) {
        props.icon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      } else {
        props.label = `${index + 1}`;
      }

      return <MarkerContainer {...props} />;
    });
  };

  return (
    <div>
      <div className="tabs is-boxed is-hidden-tablet" style={{ marginTop: '10px' }}>
        <ul>
          <li
            onClick={() => setSelectedTab(LIST)}
            className={classnames({ 'is-active': selectedTab === LIST })}
          >
            {/*eslint-disable-next-line */}
            <a>
              <span className="icon is-small">
                <i className="fas fa-list" aria-hidden="true"></i>
              </span>
              <span>List</span>
            </a>
          </li>
          <li
            onClick={() => setSelectedTab(MAP)}
            className={classnames({ 'is-active': selectedTab === MAP })}
          >
            {/*eslint-disable-next-line */}
            <a>
              <span className="icon is-small">
                <i className="fas fa-map" aria-hidden="true"></i>
              </span>
              <span>Map</span>
            </a>
          </li>
        </ul>
      </div>

      {selectedTab === LIST && <div>{renderAreaItems()}</div>}

      {selectedTab === MAP && (
        <div style={{ position: 'relative', height: '65vh' }}>
          <MapContainer {...areas[0]}>{renderMarkers()}</MapContainer>
        </div>
      )}
    </div>
  );
};

export default AreasTabbed;
