import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AreaState from './context/area/AreaState';
import Navbar from './components/navbar/Navbar';
import Home from './components/pages/Home';
import Place from './components/pages/Place';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';

import 'bulma/css/bulma.css';

function App() {
  return (
    <AreaState>
      <Router>
        <div className="App">
          <Navbar />
          <div className="section">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/place" component={Place} />
              <Route exact path="/about" component={About} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    </AreaState>
  );
}

export default App;
