import React from 'react';
import { Marker } from 'google-maps-react';

export const MarkerContainer = ({ map, google, mapCenter, area, ...props }) => {
  return (
    <Marker
      map={map}
      google={google}
      mapCenter={mapCenter}
      position={{ lat: area.latitude, lng: area.longitude }}
      title={`${area.municipality} (${area.postal_code})`}
      name={`${area.municipality} (${area.postal_code})`}
      {...props}
    />
  );
};

export default MarkerContainer;
