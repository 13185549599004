import React from 'react';

const Radio = ({ value, label, ...props }) => {
  return (
    <label className="radio">
      <input type="radio" style={{ marginRight: '2px' }} value={value} {...props} />
      {label}
    </label>
  );
};

export default Radio;
