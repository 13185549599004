import React from 'react';
import SchoolItem from './SchoolItem';

const Schools = ({ schools }) => {
  return (
    <div className="columns is-multiline">
      {schools.map(school => (
        <div key={school.id} className="column is-one-third">
          <SchoolItem {...school} />
        </div>
      ))}
    </div>
  );
};

export default Schools;
