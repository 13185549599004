import React, { useContext, useState } from 'react';
import AreaContext from '../../context/area/areaContext';
import Spinner from '../generic/spinner/Spinner';
import AreaItem from './AreaItem';
import MapContainer from '../map/MapContainer';
import MarkerContainer from '../map/MarkerContainer';

const AreasFull = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const areaContext = useContext(AreaContext);
  const { loading, areas } = areaContext;
  const refs = [];

  if (loading) {
    return <Spinner />;
  }

  const handleAreaClick = index => {
    setSelectedIndex(index);
  };

  const handleMapClick = index => {
    refs[index].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    setSelectedIndex(index);
  };

  const renderAreaItems = () => {
    return areas.map((area, index) => {
      refs.push(React.createRef());

      return (
        <AreaItem
          key={area.postal_code}
          ref={refs[index]}
          rank={index + 1}
          selected={selectedIndex === index}
          onClick={() => handleAreaClick(index)}
          area={area}
        />
      );
    });
  };

  const renderMarkers = () => {
    return areas.map((area, index) => {
      const props = {
        key: area.postal_code,
        area: area,
        onClick: () => handleMapClick(index)
      };

      if (selectedIndex === index) {
        props.icon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      } else {
        props.label = `${index + 1}`;
      }

      return <MarkerContainer {...props} />;
    });
  };

  return (
    <div className="columns">
      <div className="column is-one-third">{renderAreaItems()}</div>
      <div className="column" style={{ position: 'relative' }}>
        <div style={{ position: 'sticky', top: '0px', height: '65vh' }}>
          <MapContainer {...areas[0]}>{renderMarkers()}</MapContainer>
        </div>
      </div>
    </div>
  );
};

export default AreasFull;
