import React, { useReducer } from 'react';
import axios from 'axios';
import AreaContext from './areaContext';
import AreaReducer from './areaReducer';
import { SEARCH_AREAS, CLEAR_AREAS, SET_LOADING } from '../types';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const AreaState = props => {
  const inititalState = {
    areas: [],
    loading: false
  };

  const [state, dispatch] = useReducer(AreaReducer, inititalState);

  const buildWeightUrlParams = (travelTimeWeight, budgetWeight, schoolWeight) =>
    `commute_weight=${travelTimeWeight}&school_weight=${schoolWeight}&budget_weight=${budgetWeight}`;

  const buildRequiredUrlParams = (postalCode, travelTime, budget) =>
    `origin=${postalCode}&duration=${travelTime}&budget=${budget}`;

  const searchAreas = async ({
    postalCode,
    travelTime,
    travelType,
    budget,
    travelTimeWeight,
    budgetWeight,
    schoolWeight
  }) => {
    setLoading();

    const requiredParms = buildRequiredUrlParams(postalCode, travelTime, budget);

    const weightParams = buildWeightUrlParams(
      travelTimeWeight,
      budgetWeight,
      schoolWeight
    );

    const res = await axios.get(
      `${baseUrl}/${travelType}?${requiredParms}&${weightParams}`
    );

    dispatch({
      type: SEARCH_AREAS,
      payload: res.data
    });
  };

  const clearAreas = () => dispatch({ type: CLEAR_AREAS });

  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <AreaContext.Provider
      value={{
        areas: state.areas,
        loading: state.loading,
        searchAreas,
        clearAreas
      }}
    >
      {props.children}
    </AreaContext.Provider>
  );
};

export default AreaState;
