import React from 'react';
import moment from 'moment';
import { formatCurrency, formatNumber } from '../../utils/format';
import Schools from './Schools';

const AreaItem = ({
  postal_code,
  municipality,
  state_province,
  travel_time_to_origin,
  travel_mode,
  schools,
  census_data
}) => {
  const location = `${municipality}, ${state_province}  ${postal_code}`;
  const travelTime = moment.duration(travel_time_to_origin, 'seconds').humanize();

  return (
    <div className="content">
      <h1 className="title">{location}</h1>
      <h3 className="subtitle">General</h3>
      <ul>
        <li>
          Travel Time: {travelTime} ({travel_mode})
        </li>
        {census_data.population_total && (
          <li>
            Population:&nbsp;
            {formatNumber(census_data.population_total)}
          </li>
        )}
        {census_data.median_house_value_2015_plus &&
          census_data.median_house_value_2015_plus > 0 && (
            <li>
              Average Home Value:&nbsp;
              {formatCurrency(census_data.median_house_value_2015_plus)}
            </li>
          )}
        {census_data.median_rent && census_data.median_rent > 0 && (
          <li>
            Average Rent:&nbsp;
            {formatCurrency(census_data.median_rent)}
          </li>
        )}
        {census_data.median_household_income &&
          census_data.median_household_income > 0 && (
            <li>
              Average Household Income:&nbsp;
              {formatCurrency(census_data.median_household_income)}
            </li>
          )}
        {census_data.percent_families_with_children &&
          census_data.percent_families_with_children > 0 && (
            <li>
              Families with Children:&nbsp;
              {census_data.percent_families_with_children}%
            </li>
          )}
      </ul>
      <h3 className="subtitle">Schools</h3>
      <Schools schools={schools} />
    </div>
  );
};

export default AreaItem;
