import React from 'react';

const SchoolItem = ({
  postal_code,
  municipality,
  state_province,
  name,
  address,
  enrollment,
  district,
  grade,
  proficiency_rate_total,
  min_grade,
  max_grade
}) => {
  const location = `${municipality}, ${state_province}  ${postal_code}`;
  const minGrade = min_grade < 1 ? 'K' : '1';
  const schoolType = `${grade} (Grades: ${minGrade} - ${max_grade})`;

  return (
    <div className="box">
      <div className="content">
        <h3 className="title">{name}</h3>
        <h5 className="subtitle">{schoolType}</h5>
        <ul>
          <li>
            {address}
            <br />
            {location}
          </li>
          <li>District: {district}</li>
          <li>Rating: {enrollment}</li>
          <li>Proficiency Score: {proficiency_rate_total}</li>
        </ul>
      </div>
    </div>
  );
};

export default SchoolItem;
