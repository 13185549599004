import React from 'react';
import Control from './Control';

const SubmitControl = ({ text, ...props }) => {
  return (
    <Control>
      <button className="button is-link" type="submit" {...props}>
        {text}
      </button>
    </Control>
  );
};

export default SubmitControl;
