import React, { Fragment } from 'react';
import Area from '../area/Area';
import { useLocation } from 'react-router-dom';

const Place = () => {
  const location = useLocation();
  return (
    <Fragment>
      <Area {...location.state} />
    </Fragment>
  );
};

export default Place;
