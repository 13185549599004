import React from 'react';
import AreasTabbed from './AreasTabbed';
import AreasFull from './AreasFull';

const Areas = () => {
  return (
    <div>
      <div className="is-hidden-mobile">
        <AreasFull />
      </div>
      <div className="is-hidden-tablet">
        <AreasTabbed />
      </div>
    </div>
  );
};

export default Areas;
