import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          NeighborShould
        </Link>
        <div
          className={classnames('navbar-burger', { 'is-active': open })}
          onClick={() => setOpen(!open)}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <div
        className={classnames('navbar-menu', { 'is-active': open })}
        onClick={() => setOpen(!open)}
      >
        <div className="navbar-end">
          <Link className="navbar-item" to="/">
            Home
          </Link>
          <Link className="navbar-item" to="/about">
            About
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
