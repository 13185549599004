import React, { Fragment } from 'react';

const About = () => {
  return (
    <Fragment>
      <h1>About NeighborShould</h1>
      <p>NeighborShould helps you find the right neighborhood for you!</p>
      <p>Version: 0.0.1</p>
    </Fragment>
  );
};

export default About;
