import React, { Fragment, useContext } from 'react';
import AreaContext from '../../context/area/areaContext';
import Search from '../area/Search';
import Areas from '../area/Areas';

const Home = () => {
  const { areas } = useContext(AreaContext);

  return (
    <Fragment>
      <Search />
      {areas && areas.length > 0 && <Areas />}
    </Fragment>
  );
};

export default Home;
