import React, { useState, useContext } from 'react';
import AreaContext from '../../context/area/areaContext';
import TextControl from '../generic/TextControl';
import Field from '../generic/Field';
import Control from '../generic/Control';
import Radio from '../generic/Radio';
import SubmitControl from '../generic/SubmitControl';

const Search = () => {
  const areaContext = useContext(AreaContext);
  const initialSearch = {
    travelType: 'driving',
    travelTime: '',
    postalCode: '',
    budget: '',
    travelTimeWeight: '',
    budgetWeight: '',
    schoolWeight: ''
  };

  const [search, setSearch] = useState(initialSearch);
  const [advanced, setAdvanced] = useState(false);

  const onChange = e =>
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });

  const toggleAdvanced = () => setAdvanced(!advanced);

  const onSubmit = e => {
    e.preventDefault();
    if (!search.postalCode || !search.travelType || !search.travelTime) {
      // TODO: set alert here
      console.log('ERROR: missing required fields.');
    } else {
      areaContext.searchAreas(search);
      // setAdvanced(false);
      // setSearch(initialSearch);
    }
  };

  return (
    <form onSubmit={onSubmit} className="form">
      <Field className="is-grouped is-grouped-multiline">
        <TextControl
          label="Zip Code"
          name="postalCode"
          value={search.postalCode}
          onChange={onChange}
          leftIcon="fa-map-marker-alt"
        />
        <TextControl
          label="Max Travel Time (minutes)"
          name="travelTime"
          type="number"
          value={search.travelTime}
          onChange={onChange}
          leftIcon="fa-clock"
        />
        <TextControl
          label="Budget (Dollars)"
          name="budget"
          type="number"
          value={search.budget}
          onChange={onChange}
          leftIcon="fa-money-bill"
        />
      </Field>

      <Field>
        <Control>
          <Radio
            value="driving"
            label="Driving"
            name="travelType"
            checked={search.travelType === 'driving'}
            onChange={onChange}
          />
          <Radio
            value="bicycling"
            label="Bicycling"
            name="travelType"
            checked={search.travelType === 'bicycling'}
            onChange={onChange}
          />
          <Radio
            value="transit"
            label="Public Transit"
            name="travelType"
            checked={search.travelType === 'transit'}
            onChange={onChange}
          />
        </Control>
      </Field>

      {advanced && (
        <Field className="is-grouped is-grouped-multiline">
          <TextControl
            label="Travel Time Weight"
            name="travelTimeWeight"
            type="number"
            value={search.travelTimeWeight}
            onChange={onChange}
            className="is-small"
            leftIcon="fa-balance-scale-right"
            rightIcon="fa-clock"
          />
          <TextControl
            label="Budget Weight"
            name="budgetWeight"
            type="number"
            value={search.budgetWeight}
            onChange={onChange}
            className="is-small"
            leftIcon="fa-balance-scale-right"
            rightIcon="fa-money-bill"
          />
          <TextControl
            label="School Weight"
            name="schoolWeight"
            type="number"
            value={search.schoolWeight}
            onChange={onChange}
            className="is-small"
            leftIcon="fa-balance-scale-right"
            rightIcon="fa-graduation-cap"
          />
        </Field>
      )}

      <Field className="is-grouped is-grouped-multiline">
        <SubmitControl text="Submit" disabled={areaContext.loading} />
        <button
          type="button"
          className="button is-text has-text-link"
          onClick={toggleAdvanced}
        >
          {advanced ? 'Hide' : 'Advanced'}
        </button>
      </Field>
    </form>
  );
};

export default Search;
