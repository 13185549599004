import React from 'react';
import Control from './Control';
import classnames from 'classnames';

const TextControl = ({ label, onChange, leftIcon, rightIcon, className, ...props }) => {
  if (!props.type) {
    props.type = 'text';
  }

  return (
    <Control
      className={classnames({
        'has-icons-left': leftIcon,
        'has-icons-right': rightIcon
      })}
    >
      <input
        className={classnames('input', className)}
        placeholder={label}
        onChange={onChange}
        {...props}
      />
      {leftIcon && (
        <span className="icon is-left">
          <i className={classnames('fas', leftIcon)}></i>
        </span>
      )}
      {rightIcon && (
        <span className="icon is-right">
          <i className={classnames('fas', rightIcon)}></i>
        </span>
      )}
    </Control>
  );
};

export default TextControl;
