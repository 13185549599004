import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import { formatCurrency, formatNumber } from '../../utils/format';

const AreaItem = React.forwardRef((props, ref) => {
  const { rank, selected, onClick, area } = props;
  const {
    postal_code,
    municipality,
    state_province,
    travel_time_to_origin,
    census_data
  } = area;

  const location = `${municipality}, ${state_province}  ${postal_code}`;
  const travelTime = moment.duration(travel_time_to_origin, 'seconds').humanize();

  return (
    <div
      ref={ref}
      className={classnames('box', { 'has-background-grey-lighter': selected })}
    >
      <div className="content">
        <h3 className="title">{location}</h3>
        <h5 className="subtitle">{travelTime}</h5>
        <ul>
          {census_data.population_total && (
            <li>
              Population:&nbsp;
              {formatNumber(census_data.population_total)}
            </li>
          )}
          {census_data.median_house_value_2015_plus &&
            census_data.median_house_value_2015_plus > 0 && (
              <li>
                Average Home Value:&nbsp;
                {formatCurrency(census_data.median_house_value_2015_plus)}
              </li>
            )}
          {census_data.median_rent && census_data.median_rent > 0 && (
            <li>
              Average Rent:&nbsp;
              {formatCurrency(census_data.median_rent)}
            </li>
          )}
        </ul>
        <div>
          <Link
            className="button is-primary is-outlined"
            to={{ pathname: '/place', state: area }}
          >
            Details
          </Link>
          <button
            className="button is-link is-outlined"
            onClick={onClick}
            style={{ marginLeft: '5px' }}
          >
            Select
          </button>
          <div className="rank is-pulled-right has-text-info is-size-4">#{rank}</div>
        </div>
      </div>
    </div>
  );
});

export default AreaItem;
